







import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class EditButton extends Vue {
  @Prop({
    type: Function,
  })
  private click!: () => void;

  @Prop({
    type: String,
    default: "Edit",
  })
  private txt!: () => string;
}
