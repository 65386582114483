import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import {
  FullDetailUserInterface,
  RoleInterface,
  ToBeUpdateFullDetailUserInterface,
  UserProject,
  UserProjectList,
} from "@/types/user.type";
import {
  getAllUsers,
  getRoles,
  getUserById,
  updateUserDetails,
  getUserProject,
  resetLeaveLimits,
} from "@/services/user.service";

@Module({
  dynamic: true,
  name: "userManagement",
  store,
})
class UserManagement extends VuexModule {
  public users: FullDetailUserInterface[] = [];
  public userProjectList: UserProjectList[] = [];
  public roles: RoleInterface[] = [];
  public currentUser: object = {};

  get getCurrentEditingUser() {
    return this.currentUser as FullDetailUserInterface;
  }

  @MutationAction({ mutate: ["users"], rawError: true })
  public async getAllUsers() {
    const users: FullDetailUserInterface[] = await getAllUsers();
    return { users };
  }

  @MutationAction({ mutate: ["roles"], rawError: true })
  public async getRoles() {
    const roles = await getRoles();
    return { roles };
  }

  @Action({ rawError: true })
  public async updateUserProfile(payload: { id: string; userData: ToBeUpdateFullDetailUserInterface }) {
    await updateUserDetails(payload.id, payload.userData);
  }

  @Action({ rawError: true })
  public async getUserById(id: string) {
    const currentUser: FullDetailUserInterface = await getUserById(id);
    this.SET_CURRENT_USER(currentUser);
  }

  @Action({ rawError: true })
  public async getUserProjectListById(id: string) {
    const data: UserProject[] = await getUserProject(id);
    const projects: UserProjectList[] = data.map((up) => {
      const userProjectList: UserProjectList = {
        projectId: up.projectDetail.id,
        projectDisplay: up.projectDetail.name,
        // Project Member
        projectMemberId: up.projectMember.id,
        projectMemberCostPerUnit: up.projectMember.costPerUnit,
        projectMemberMaximumUnit: up.projectMember.maximumUnit,
        // Project Position
        positionId: up.position.id,
        positionDisplay: up.position.name,
      };
      return userProjectList;
    });
    this.SET_USER_PROJECT_LIST(projects);
  }

  @Action({ rawError: true })
  public async resetLeaveLimits(ids: string[]) {
    return resetLeaveLimits({ userIds: ids });
  }

  @Mutation
  private SET_USER_PROJECT_LIST(data: UserProjectList[]) {
    this.userProjectList = data;
  }

  @Mutation
  private SET_CURRENT_USER(user: FullDetailUserInterface) {
    // transform leave limit
    user.leaveRequestLimit = user.leaveRequestLimit.map((data: any) => {
      return {
        reasonId: data.reason.id,
        reasonName: data.reason.name,
        limit: data.limit,
      };
    });

    this.currentUser = user;
  }
}

export const UserManagementModule = getModule(UserManagement);
