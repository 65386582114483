









































































































































































































import { Component, Ref, Vue } from "vue-property-decorator";
import { RoleInterface, ToBeUpdateFullDetailUserInterface } from "@/types/user.type";
import { UserManagementModule } from "@/store/modules/user-management-module";
import { VuetifyForm } from "@/types/vuetify.type";
import { ErrorModule } from "@/store/modules/error";
import NotFound from "@/components/NotFound.vue";
import { DataTableHeader } from "vuetify";
import TableComponent from "@/components/TableComponent.vue";
import Header from "@/components/Header.vue";
import EditButton from "@/components/buttons/EditButton.vue";
import Dialog from "@/components/Dialog.vue";
import UserForm from "@/components/user/UserForm.vue";
import { LeaveRequestModule } from "@/store/modules/leave-request-module";
import { UserModule } from "@/store/modules/user-module";
import moment from "moment";
import InfoComponent from "@/components/InfoComponent.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";

@Component({
  name: "EditUser",
  components: {
    NotFound,
    TableComponent,
    Header,
    EditButton,
    Dialog,
    UserForm,
    InfoComponent,
    SaveButton,
    ConfirmationDialog,
  },
})
export default class extends Vue {
  public $refs!: {
    deleteDialog: HTMLFormElement;
    dialog: HTMLFormElement;
  };

  private get leaveRequestDateRangeText() {
    return this.leaveRequestDateRange.join(" to ");
  }

  private get leaveRequests(): any {
    return LeaveRequestModule.allLeaveRequest;
  }

  private get leaveRequestHeaders(): DataTableHeader[] {
    return [
      {
        text: "Date",
        value: "startDate",
      },
      {
        text: "Reason",
        value: "reasonName",
      },
      {
        text: "Notes / Comment",
        value: "notes",
      },
      {
        text: "Approve",
        value: "approved",
      },
      {
        text: "Approve By",
        value: "approvedByInfo",
      },
      {
        text: "Action",
        value: "action",
      },
    ];
  }

  private get allLeaveCounts(): object {
    return LeaveRequestModule.allLeaveCounts;
  }

  get userProjectList() {
    return UserManagementModule.userProjectList;
  }

  get isNotFound(): boolean {
    return ErrorModule.isNotFound;
  }
  public items = [
    {
      text: "Users",
      exact: true,
      disabled: false,
      to: "user/",
    },
  ];

  private displayName = "";
  private userData: ToBeUpdateFullDetailUserInterface = {
    id: -1,
    realId: -1,
    username: "",
    email: "",
    firstName: "",
    lastName: "",
    nickname: "",
    status: false,
    role: null,
    isSuperuser: false,
    dateJoined: new Date(),
    leaveRequestLimit: [],
  };

  private roles: RoleInterface[] = [];
  private noneEmptyRule = [(v: string) => !!v || "This field is required"];
  private valid = false;

  private userProjectListHeaders: DataTableHeader[] = [
    { text: "Project Name", value: "projectDisplay" },
    { text: "Position", value: "positionDisplay" },
  ];

  private leaveRequestFilter: any = {
    userId: UserModule.userProfile.realId,
    reason: -1,
    status: "All",
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: Date.now(),
  };

  private leaveRequestDateRange: string[] = [
    moment().subtract(30, "days").format("YYYY-MM-DD"),
    moment().format("YYYY-MM-DD"),
  ];

  @Ref()
  private readonly form!: VuetifyForm;

  private menu: boolean = false;

  private leaveRequestStatus: any = ["All", "Approved", "Pending", "Rejected"];

  private leaveRequestId: number | null | string = 1;

  private leaveReasonSelector: object = [
    { id: -1, name: "All" },
    { id: 1, name: "Personal Leave" },
    { id: 2, name: "Vacation Leave" },
    { id: 3, name: "Sick Leave" },
  ];

  public async mounted() {
    const id = this.$route.params.id;
    if (UserManagementModule.roles.length === 0) {
      await UserManagementModule.getRoles();
    }
    this.roles = UserManagementModule.roles;
    await UserManagementModule.getUserById(id);
    const { role, ...userData } = UserManagementModule.getCurrentEditingUser;
    this.userData = { role: role.id, ...userData, dateJoined: userData.dateJoined };
    this.displayName = `${this.userData.firstName} ${this.userData.lastName}`;
    this.items.push({
      exact: false,
      text: this.displayName,
      disabled: true,
      to: "",
    });
    await UserManagementModule.getUserProjectListById(String(userData.realId));
    await LeaveRequestModule.getAllLeaveCounts(Number(id));
    this.leaveRequestFilter.userId = this.userData.realId;
    this.leaveRequestFilter.startDate = this.leaveRequestDateRange[0];
    this.leaveRequestFilter.endDate = this.leaveRequestDateRange[1];
    await LeaveRequestModule.filterLeaveRequest(this.leaveRequestFilter);
  }

  private openEditDialog() {
    (this.$refs.dialog as any).openDialog();
  }

  private closeEditDialog() {
    (this.$refs.dialog as any).simpleCloseDialog();
  }

  private async save() {
    const id = this.$route.params.id;
    const sending = { id, userData: { ...this.userData } };
    sending["userData"]["dateJoined"] = new Date(sending["userData"]["dateJoined"]).toISOString();
    try {
      await UserManagementModule.updateUserProfile(sending);
      (this.$refs.dialog as any).closeDialog(true);
    } catch {
      (this.$refs.dialog as any).closeDialog(false);
    }
  }

  private back() {
    this.$router.push({ name: "Users" });
  }

  private leaveRequestUpdateDateRange(newValue: string[]) {
    this.leaveRequestDateRange = newValue;
    this.leaveRequestDateRange.sort();
    if (this.leaveRequestDateRange.length === 2) {
      this.menu = false;
    }
  }

  private openLeaveRequestDeleteDialog(id: number) {
    this.leaveRequestId = id;
    this.$refs.deleteDialog.openDialog();
  }

  private async deleteLeaveRequest() {
    try {
      await LeaveRequestModule.deleteLeaveRequest(this.leaveRequestId as number);
      this.$refs.deleteDialog.closeDialog();
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete leave request successfully!",
    });
    await LeaveRequestModule.getAllLeaveCounts(Number(this.$route.params.id));
  }

  private clickFilter() {
    this.leaveRequestFilter.startDate = this.leaveRequestDateRange[0];
    this.leaveRequestFilter.endDate = this.leaveRequestDateRange[1];
    LeaveRequestModule.filterLeaveRequest(this.leaveRequestFilter);
  }
}
