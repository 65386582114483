






































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import CloseButton from "@/components/buttons/CloseButton.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import Divider from "@/components/Divider.vue";
import Header from "@/components/Header.vue";
import { VuetifyForm } from "@/types/vuetify.type";

@Component({
  components: {
    SaveButton,
    Divider,
    CloseButton,
    Header,
  },
})
export default class ClientForm extends Vue {
  private noneEmptyRule = [(v: string) => !!v || "This field is required"];

  private editDateJoinedMenu: boolean = false;

  @Ref()
  private readonly form!: VuetifyForm;

  @Prop({
    type: Object,
  })
  private readonly userData!: { dateJoined: Date };

  @Prop({
    type: String,
    default: "",
  })
  private readonly displayName!: string;

  @Prop({
    default: [],
  })
  private readonly roles!: any;

  @Prop({
    type: Function,
  })
  private afterSubmit!: () => void;

  @Prop({
    type: Function,
  })
  private simpleClose!: () => void;

  private async clickSave() {
    if (!this.form.validate()) {
      return;
    }
    this.afterSubmit();
  }
}
